import http from "../http-common";
const getAllEmployees = (role, status) => {
  const params = {};
  if (role) {
    params.roles = role;
  }
  if (status) {
    params.status = status;
  }
  return http.get('/employees', {
    params
  });
};

const getEmployee = (id) => {
  return http.get(`/employees/${id}`);
}

const createNewEmployee = (data) => {
  data.status = 'active';
	return http.post('/employees', data);
};

const updateEmployee = (id, data) => {
  return http.put(`/employees/${id}`, data);
}

const deleteEmployee = (id, data) => {
  data.status = 'inactive';
  return http.put(`/employees/${id}`, data);
}

const validatePassword = (password = '') => {
  const lowercaseRegex = /[a-z]/;
  const uppercaseRegex = /[A-Z]/;
  const specialCharRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  if (password?.length < 8) {
    return false;
  }

  if (!lowercaseRegex.test(password)) {
    return false; // Missing lowercase letter
  }

  if (!uppercaseRegex.test(password)) {
    return false; // Missing uppercase letter
  }

  if (!specialCharRegex.test(password)) {
    return false; // Missing special character
  }

  return true; // Password meets criteria
}

export const EmployeeService = {
  getAllEmployees,
  createNewEmployee,
  updateEmployee,
  deleteEmployee,
  getEmployee,
  validatePassword
};
