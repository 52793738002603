import React, {useState, useEffect} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { driverSlice } from "./../../store";
import { employeeSlice } from "../../store/employees";
import { AuthService, DispatcherService, EmployeeService } from "../../services";
import { EMPLOYEE_TITLE, EMPLOYEE_TITLE_CN, EMPLOYEE_TITLE_ROLES_MAP, INVITATION_CODE } from "../../shared";

const CreateEmployee = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { createDriver } = driverSlice.actions;
  const { createEmployee} = employeeSlice.actions;
	const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
	const [nameCN, setNameCN] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [roles, setRoles] = useState('');
	const [email, setEmail] = useState('');
  const [driverCapacity, setDriverCapacity] = useState();
	const [mobilePhone, setMobilePhone] = useState('');
	const [phone, setPhone] = useState('');
  const [homePhone, setHomePhone] = useState('');
  const [language, setLanguage] = useState('');
  const [employmentStatus, setEmploymentStatus] = useState('');
  const [address, setAddress] = useState('');
  const [title, setTitle] = useState('');
  const [titleCN, setTitleCN] = useState('');
  const [department, setDepartment] = useState('');
  const [dateHired, setDateHired] = useState('');
  const [note, setNote] = useState('');
  const [tags, setTags] = useState('');
  const [showSaveInfo, setShowSaveInfo] = useState(false);
  const [invitationCode, setInvitationCode] = useState('');
  const params = new URLSearchParams(window.location.search);
  const redirectTo = () => {
		const redirect = params.get('redirect');
    const type = params.get('type');
		if (redirect === 'schedule') {
			navigate(`/trans-routes/schedule`);
		} else {
      if (type === 'driver') {
        navigate(`/trans-routes/dashboard`);
      } else {
        navigate('/admin');
      }
		}
  }

  const goToLogin = () => {
    navigate(`/login`);
  }

  const setTitleAndRoles = (value) => {
    if (value) {
      setTitle(value);
      setTitleCN(EMPLOYEE_TITLE_CN[value]);
      setRoles(EMPLOYEE_TITLE_ROLES_MAP[value]?.join(','));
    } else {
      setTitle('');
      setTitleCN('');
      setRoles('');
    }
  }

  useEffect(() => {
    if ((!AuthService.canAddOrEditEmployees() && !(AuthService.canCreateOrEditDrivers() && params.get('type')==='driver') && params.get('type') !== 'dispatcher' )) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
    if (params.get('type') === 'driver' || params.get('type') === 'dispatcher') {
      setTitleAndRoles('DRIVER');
    }
  }, [])

  const saveEmployee = () => {
    if (params.get('type') === 'dispatcher' && !INVITATION_CODE.includes(invitationCode)) {
      window.alert('Invitation Code is not correct')
    } else {
      if (!EmployeeService.validatePassword(password)) {
        window.alert("Password's length must be longer than 8. It must contain 1 uppercase character, 1 lowercase character and 1 special character.")
      } else {
        const data = {
          username,
          name: `${lastname},${firstname}`,  
          name_cn: nameCN,
          email,
          password,
          mobile_phone: mobilePhone,
          phone,  
          home_phone: homePhone,
          language, 
          employment_status: employmentStatus,
          status: 'active',
          address,
          title,
          title_cn: titleCN,
          firstname,
          lastname,
          department,
          birth_date: birthDate,
          driver_capacity: driverCapacity,
          date_hired: dateHired,
          create_by: 'admin',
          edit_by: 'admin',
          note,
          tags: tags.replace(' ', '').split(','),
          roles: roles && roles.replace(' ', '').split(',')
        };
        if (params.get('type') === 'driver') {
          dispatch(createDriver({data}));
        } else {
          if (params.get('type') === 'dispatcher') {
            DispatcherService.createNewDispatcher(data).then(() => {
              setShowSaveInfo(true);
            });
          } else {
            dispatch(createEmployee({data}));
          }
         
        }
        if (params.get('type') !== 'dispatcher') {
          redirectTo();
        }
      }
    }
  };

  
  return (
    <>
      <div className="list row mb-4">
        <div className="col-md-12 text-primary">
           <h5>Create New Employee <button className="btn btn-link btn-sm" onClick={() => {redirectTo()}}>Back</button></h5> 
        </div>
        {showSaveInfo && <div className="col-md-12 mb-4 alert alert-success" role="alert">
					 Dispatcher Info Created Successfully. Please contact Admin to Activate your Account before login.
           <button className="btn btn-link btn-sm" onClick={() => goToLogin()}>Go To Login</button>
				</div>}
      </div>
      <div className="list row mb-4">
        { params.get('type') === 'dispatcher' && <div className="col-md-4 mb-4">
          <div>Invitation Code(*):</div> <input type="text" value={invitationCode || ''} onChange={e => setInvitationCode(e.target.value)}/>
        </div>}
        <div className="col-md-4 mb-4">
          <div>Username (used for login)(*):</div> <input type="text" value={username || ''} onChange={e => setUsername(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Password (used for login)(*):</div> <input type="text" value={password || ''} onChange={e => setPassword(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>First Name:</div> <input type="text" value={firstname || ''} onChange={e => setFirstname(e.target.value)}/>
        </div>
				<div className="col-md-4 mb-4">
			  	<div>Last Name:</div> <input type="text" value={lastname || ''} onChange={e => setLastname(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
          <div>Chinese Name (中文姓名):</div> <input type="text" value={nameCN || ''} onChange={e => setNameCN(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Birth Date (Type in as: MM/DD/YYYY):</div> <input type="text" value={birthDate || ''} onChange={e => setBirthDate(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Title(*):</div> <select value={title} onChange={(e) => setTitleAndRoles(e.target.value)}>
            <option value=""></option>
            {
              Object.entries(EMPLOYEE_TITLE).map(([key, value]) => <option value={key} key={key}>{value}</option>)
            }
          </select>
        </div>
        <div className="col-md-4 mb-4">
					<div>Title CN(中文称谓):</div> <input type="text" value={titleCN || ''} onChange={e => setTitleCN(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Roles(*):</div> <input type="text" value={roles || ''} onChange={e => setRoles(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Email(*):</div> <input type="email" value={email || ''} onChange={e => setEmail(e.target.value)}/>
        </div>
				<div className="col-md-4 mb-4">
			  	<div>Driver Capacity:</div> <input type="number" value={driverCapacity || ''} onChange={e => setDriverCapacity(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Phone :</div> <input type="text" value={phone || ''} onChange={e => setPhone(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
          <div>Mobile Phone:</div> <input type="text" value={mobilePhone || ''} onChange={e => setMobilePhone(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Home Phone:</div> <input type="text" value={homePhone || ''} onChange={e => setHomePhone(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Language(Please use ',' between each language):</div> <input type="text" value={language || ''} onChange={e => setLanguage(e.target.value)}/>
        </div>
				<div className="col-md-4 mb-4">
			  	<div>Employment Type:</div> <select value={employmentStatus} onChange={e => setEmploymentStatus(e.target.value)}>
            <option value=""></option>
            <option value="fulltime">Full Time</option>
            <option value="parttime">Part Time</option>
            <option value="volunteer">Volunteer</option>
          </select>
        </div>
        <div className="col-md-4 mb-4">
          <div>Address:</div> <input type="text" value={address || ''} onChange={e => setAddress(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Department:</div> <input type="text" value={department || ''} onChange={e => setDepartment(e.target.value)}/>
        </div>
				<div className="col-md-4 mb-4">
			  	<div>Date Hired(Type in as 'MM/DD/YYYY'):</div> <input type="text" value={dateHired || ''} onChange={e => setDateHired(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
			  	<div>Note:</div> <input type="text" value={note || ''} onChange={e => setNote(e.target.value)}/>
        </div>
        <div className="col-md-4 mb-4">
					<div>Tags(Please use ',' between each tags):</div> <input type="text" value={tags || ''} onChange={e => setTags(e.target.value)}/>
        </div>
				
      </div>
      <div className="list row mb-5">
        <div className="col-md-6 col-sm-6 col-xs-12 mb-4">
          <button className="btn btn-primary btn-sm" onClick={() => saveEmployee()}> Save </button>
          <button className="btn btn-default btn-sm" onClick={() => redirectTo()}> Cancel </button>
        </div>
        {showSaveInfo && <div className="col-md-12 mb-4 alert alert-success" role="alert">
					 Dispatcher Info Created Successfully. Please contact Admin to Activate your Account before login.
           <button className="btn btn-link btn-sm" onClick={() => goToLogin()}>Go To Login</button>
				</div>}
      </div>
    </>
  );
};

export default CreateEmployee;