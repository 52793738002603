import React, {useState, useEffect} from "react";
// import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import { customerSlice } from "./../../store";
import { AuthService, CustomerService, EventsService, ResourceService } from "../../services";
import Select from 'react-select';
import { Button } from "react-bootstrap";
import DateTimePicker from "react-datetime-picker";
import DatePicker from "react-datepicker";

const CreateEvent = () => {
  const navigate = useNavigate();
	// const [medicalResource, setMedicalResource] = useState(undefined);
  // const [currentResource, setCurrentResource] = useState(undefined);
  const [resources, setResources] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [customerName, setCustomerName] = useState(undefined);
  const [currentCustomer, setCurrentCustomer] = useState(undefined);
  const [startTime, setStartTime] = useState('');
  // const [title, setTitle] = useState('');
	// const [description, setDescription] = useState('');
  // const [newPatient, setNewPatient] = useState('');
  // const [confirmed, setConfirmed] = useState('');
  // const [fasting, setFasting] = useState('');
  // const [interpreter, setInterpreter] = useState('');
  // const [docOrder, setDocOrder] = useState('');
  // const [disability, setDisability] = useState('');
  // const [needId, setNeedId] = useState('');
  // const [needMedicationList, setNeedMedicationList] = useState('');
  // const [disabilitySupport, setDisabilitySupport] = useState('');
  // const [notes, setNotes] = useState('');
  // const [reason, setReason] = useState('');
  // const [otherRequirement, setOtherRequirement] = useState('');
  // const [todayEvents, setTodayEvents] = useState([]);
  // const [transportationInfo, setTransportationInfo] = useState(undefined);
  
 
  const params = new URLSearchParams(window.location.search);
  const redirectToCalendar = () => {
		navigate(`/medical/events/calendar`);
  }

  const redirectToView = (id) => {
		navigate(`/medical/events/${id}`);
  }

  const redirectTo = () => {
    navigate(`/medical/events/list`);
  }

  const goToNext = (id) => {
    navigate(`/medical/events/edit/${id}?from=create`)
  }

  const saveEvent = () => {
    console.log('customer', currentCustomer);
    // console.log('resource', currentResource);
    
    const newEventData = {
      customer: currentCustomer.id,
      // resource: currentResource.id,
      client_name: currentCustomer.name,
      client_pharmacy: currentCustomer.pharmacy,
      client_pcp: currentCustomer.care_provider,
      client_birth_date: currentCustomer.birth_date,
      client_seating: currentCustomer.seating,
      // resource_type: currentResource.type,
      // resource_name: currentResource.name,
      // resource_phone: currentResource.phone,
      // resource_contact: currentResource.contact,
      // resource_address: currentResource.address,
      // resource_city: currentResource.city,
      // resource_state: currentResource.state,
      // // legacy fields end
      // // We still wanna keep the legacy fields below
      // new_patient: newPatient,
      // confirmed: confirmed,
      // fasting: fasting,
      // interpreter: interpreter,
      // doc_order: docOrder,
      // need_id: needId,
      // need_med_list: needMedicationList,
      // notes: notes,
      // reason: reason,
      // other: otherRequirement,
      disability: currentCustomer.disability? 'yes' : 'no',
      // disability_support: disabilitySupport
    }
    
    const newEvent = {
      data: newEventData,
      // title: title,
      // descrption: description,
      // notes: notes,
      start_time: new Date(startTime),
      type: 'medical',
      // stop_time: new Date(startTime),
      // source_type: currentResource.type,
      // source_uuid: currentResource.id,
      // source_name: currentResource.name,
      target_type: currentCustomer.type,
      target_uuid: currentCustomer.id,
      target_name: currentCustomer.name,
      create_by: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name,
      create_date: new Date(),
      edit_by: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name,
      edit_date: new Date(),
      edit_history:[{ employee: localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))?.name, date: new Date() }]
    }

    console.log('new Event', newEvent);
    navigate(`/medical/events/create-from-request?event=${encodeURIComponent(JSON.stringify(newEvent))}`)
    // EventsService.createNewEvent(newEvent).then(data => goToNext(data.data?.id));
  };
  

  useEffect(() => {
    if (!AuthService.canAccessLegacySystem()) {
      window.alert('You haven\'t login yet OR this user does not have access to this page. Please change an admin account to login.')
      AuthService.logout();
      navigate(`/login`);
    }
    Promise.all([ResourceService.getAll(), CustomerService.getAllCustomers()]).then(([resourcesData, customersData]) => {
      setResources(resourcesData.data);
      setCustomers(customersData.data);
    })
  }, []);

  const onCustomerChange = (selectedCustomer) => {
    setCustomerName(selectedCustomer);
    setCurrentCustomer(customers.find(customer => customer.id === selectedCustomer.value))
  }

  // const onResourceChange = (selectedResource) => {
  //   setMedicalResource(selectedResource);
  //   setCurrentResource(resources.find(resource => resource.id === selectedResource.value));
  // }

  return (
    <>
      <div className="list row">
        <div className="col-md-8 col-xs-12">
          <div className="list row mb-4">
            <div className="col-md-12 text-primary">
              <h5>Create Medical Event <button className="btn btn-link btn-sm" onClick={() => {redirectTo()}}>Back</button></h5> 
            </div>
          </div>
          <div className="list row mb-4">
            {/* <div className="col-md-6 mb-4">
            <div>Resource:</div>
              <Select value={medicalResource || ''} onChange={selectedResource => onResourceChange(selectedResource)} options={[{value: '', label: ''}, ...resources.map(resource => ({
                value: resource.id || '',
                label: resource?.name || ''
              }))]}></Select>
            </div> */}
            <div className="col-md-6 mb-4">
              <div>Client:</div>
              <Select value={customerName || ''} onChange={selectedCustomer => onCustomerChange(selectedCustomer)} options={[{value: '', label: ''}, ...customers.map(customer => ({
                value: customer?.id || '',
                label: customer?.name || ''
              }))]}></Select>
            </div>
            {/* <div className="col-md-6 mb-4">
              <div>Title:</div> <input type="text" value={title || ''} onChange={e => setTitle(e.target.value)}/>
            </div> */}
            <div className="col-md-6 mb-4">
              <div>Start Time:</div> <DatePicker
                selected={startTime}
                onChange={setStartTime}
                showTimeInput
                timeInputLabel="Time:"
                dateFormat="MM/dd/yyyy, HH:mm"
              ></DatePicker>
              {/* <div>Start Time</div> <DateTimePicker format={'MM/dd/y H:m'} disableClock={true}  value={startTime} onChange={setStartTime} /> */}
            </div>
            {/* <div className="col-md-12 mb-4">
              <div>Description:</div> <textarea value={description || ''} onChange={e => setDescription(e.target.value)}/>
            </div>
            <div className="col-md-4 mb-4">
              <div>New Patient:</div>
              <select value={newPatient} onChange={e => setNewPatient(e.target.value)}>
                <option value=""></option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="col-md-4 mb-4">
              <div>Confirmed:</div> 
              <select value={confirmed} onChange={e => setConfirmed(e.target.value)}>
                <option value=""></option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div> */}
            {/* <div className="col-md-4 mb-4">
              <div>Fasting:</div>
              <select value={fasting} onChange={e => setFasting(e.target.value)}>
                <option value=""></option>
                <option value="empty_stomach">Empty Stomach</option>
                <option value="clear_liquid">Clear Liquid</option>
              </select>
            </div>
            <div className="col-md-4 mb-4">
              <div>Interpreter Level:</div>
              <select value={interpreter} onChange={e => setInterpreter(e.target.value)}>
                <option value=""></option>
                <option value="1(译)">1(译)</option>
                <option value="2(译)">2(译)</option>
                <option value="家(译)">家(译)</option>
                <option value="越南语翻译">越南语翻译</option>
                <option value="Office">Office</option>
                <option value="Office(Phone)">Office(Phone)</option>
              </select>
            </div>
            <div className="col-md-4 mb-4">
              <div>Doctor Order:</div> 
              <select value={docOrder} onChange={e => setDocOrder(e.target.value)}>
                <option value=""></option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div> */}
            {/* <div className="col-md-4 mb-4">
              <div>Disability: </div>
              <select value={disability} onChange={e => setDisability(e.target.value)}>
                <option value=""></option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="col-md-4 mb-4">
              <div>Need Id: </div>
              <select value={needId} onChange={e => setNeedId(e.target.value)}>
                <option value=""></option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="col-md-4 mb-4">
              <div>Need Medication List</div>
              <select value={needMedicationList} onChange={e => setNeedMedicationList(e.target.value)}>
                <option value=""></option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div> */}
            {/* <div className="col-md-4 mb-4">
              <div>Disability Support:</div>
              <select value={disabilitySupport} onChange={e => setDisabilitySupport(e.target.value)}>
                <option value=""></option>
                <option value="center worker">center worker</option>
                <option value="family member">family member</option>
              </select>
            </div>
            <div className="col-md-4 mb-4">
              <div>Notes:</div><textarea value={notes || ''} onChange={e => setNotes(e.target.value)}/> 
            </div>
            <div className="col-md-4 mb-4">
              <div>Reason:</div> 
              <textarea value={reason || ''} onChange={e => setReason(e.target.value)}/>
            </div>
            <div className="col-md-4 mb-4">
              <div>Other Requirements:</div>
              <textarea value={otherRequirement || ''} onChange={e => setOtherRequirement(e.target.value)}/>
            </div> */}
          </div>
          <div className="list row mb-5">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <button className="btn btn-primary btn-sm me-2 mb-2" onClick={() => saveEvent()}> Next </button>
              <button className="btn btn-default btn-sm" onClick={() => redirectTo()}> Cancel </button>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-xs-12">
          {/* {currentResource && <fieldset>
            <legend>Resource Info</legend>
            <p>{currentResource?.name || ''}</p>
            <p>{currentResource?.specialty || ''}</p>
            <p>{currentResource?.phone || ''}</p>
            <p>{currentResource?.contact || ''}</p>
            <p>{currentResource?.address || ''}</p>
          </fieldset> }
          <p></p> */}

          {currentCustomer && <fieldset>
            <legend>Client Info</legend>
            <p>{currentCustomer?.name || ''}</p>
            <p>{currentCustomer?.name_cn || ''}</p>
            <p>{currentCustomer?.pharmacy || ''}</p>
            <p>{currentCustomer?.care_provider || ''}</p>
            <p>{currentCustomer?.birth_date || ''}</p>
          </fieldset> }

          <p></p>

          {/* {transportationInfo?.isFutureEvent && <fieldset>
            <legend>Transportation Info</legend>
            <p>translate1: {transportationInfo?.maxTranslate1 || 0}</p>
            <p>translate2: {transportationInfo?.maxTranslate1 || 0}</p>
            <p>max resource: {transportationInfo?.maxResource || 0}</p>
            <p></p>

            <h4 style={{'color': 'red'}}>Remain:</h4>
            <p>(when edit, the numbers below are for reference only)</p>
            <p>translate1: { transportationInfo?.maxTranslate1 - transportationInfo?.totalTranslate1  - (currentEvent?.data?.interpreter === '1(译)' ? 0 : (interpreter==='1(译)' ? 1 : 0)) }</p>
            <p>translate2: { transportationInfo?.maxTranslate2 - transportationInfo?.totalTranslate2 - (currentEvent?.data?.interpreter === '2(译)' ? 0 : (interpreter==='2(译)' ? 1 : 0)) }</p>
            <p>resources: {transportationInfo?.maxResource - transportationInfo?.totalResource || 0 }</p>
          </fieldset> } */}
        </div>
      </div>
        
    </>
  );
};

export default CreateEvent;